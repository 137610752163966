<template>
  <div class="night-school-detail">
    <div class="course-detail-box">
      <courseDetail :courseTypeMap="courseTypeMap" :detail="courseDetail" />
    </div>
    <div class="footer-button">
      <div v-if="ljbmShow" class="hangdle-button ljbm" @click="ljbmOnClick">立即报名</div>
      <div v-if="ybmShow" class="hangdle-button ybm" @click="ybmOnClick">已报名(查看报名订单)</div>
      <div v-if="ymyShow" class="hangdle-button disabled-btn">已满员</div>
      <div v-if="yjzbmShow" class="hangdle-button disabled-btn">截止报名</div>
      <div v-if="wdbmsjShow" class="hangdle-button disabled-btn">未开始报名</div>
    </div>
  </div>
</template>

<script>
import { getCourseDetailUrl, getCourseTypeUrl } from '../api';
import courseDetail from '../components/courseDetail.vue';
import { mapState } from 'vuex';
import { toRegister } from '@/utils/common.js';

export default {
  name: 'allCourseDetail',
  components: {
    courseDetail
  },
  data() {
    return {
      courseTypeMap: {},
      courseDetail: {
        coursePictureUrl: '',
        coursePictureUrlList: [],
        courseName: '',
        fitApplyPerson: '',
        fitApplyPersonList: [],
        courseType: void 0,
        price: void 0,
        teacherPictureUrl: '',
        teacher: '',
        teacherDescription: '',
        isAstrictPersons: void 0,
        minPerson: void 0,
        maxPerson: void 0,
        applyUserCount: void 0,
        courseSettingList: [],
        courseOpenStart: '',
        courseOpenEnd: '',
        applyDateStart: '',
        applyDateEnd: '',
        courseAddr: '',
        courseAdmin: '',
        courseAdminMobile: '',
        courseStatus: void 0,
        isApply: 0
      },
      timer: null,
      currentDate: new Date()
    };
  },
  computed: {
    ...mapState(['userId']),
    ljbmShow() {
      return (
        this.courseDetail.isApply <= 0 &&
        new Date(this.courseDetail.applyDateStart).getTime() < this.currentDate.getTime() &&
        this.currentDate.getTime() < new Date(this.courseDetail.applyDateEnd).getTime() &&
        ((this.courseDetail.isAstrictPersons === 1 && this.courseDetail.applyUserCount < this.courseDetail.maxPerson) ||
          this.courseDetail.isAstrictPersons === 0)
      );
    },
    ybmShow() {
      return this.courseDetail.isApply > 0;
    },
    ymyShow() {
      return (
        this.courseDetail.isApply <= 0 &&
        new Date(this.courseDetail.applyDateStart).getTime() < this.currentDate.getTime() &&
        this.currentDate.getTime() < new Date(this.courseDetail.applyDateEnd).getTime() &&
        this.courseDetail.isAstrictPersons === 1 &&
        this.courseDetail.applyUserCount >= this.courseDetail.maxPerson
      );
    },
    yjzbmShow() {
      return this.courseDetail.isApply <= 0 && this.currentDate.getTime() > new Date(this.courseDetail.applyDateEnd).getTime();
    },
    wdbmsjShow() {
      return this.courseDetail.isApply <= 0 && this.currentDate.getTime() < new Date(this.courseDetail.applyDateStart).getTime();
    }
  },
  created() {
    this.$axios
      .get(getCourseTypeUrl, {})
      .then(res => {
        if (res && res.code === 200) {
          res.data = Array.isArray(res.data) ? res.data : [];
          const courseTypeMap = {};
          res.data.forEach(item => {
            courseTypeMap[item.id] = item.courseType;
          });
          this.courseTypeMap = courseTypeMap;
        }
        this.getDetail();
      })
      .catch(() => {
        this.getDetail();
      });
    this.satrtInterval();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    satrtInterval() {
      if (this.timer) clearImmediate(this.timer);
      this.timer = setInterval(() => {
        this.currentDate = new Date();
      }, 1000);
    },
    getDetail() {
      this.$axios
        .get(getCourseDetailUrl, { params: { id: this.$route.query.id, userId: this.userId } })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.isApply = [void 0, '', null, NaN].includes(res.data.isApply) ? 0 : res.data.isApply;
            res.data.coursePictureUrlList = res.data.coursePictureUrl.split('|').filter(item => item);
            res.data.fitApplyPersonList = res.data.fitApplyPerson.split(',').filter(item => item);
            Object.assign(this.courseDetail, res.data);
          }
        })
        .catch(() => {});
    },
    async ljbmOnClick() {
      if (!this.ljbmShow) return;
      if (await toRegister(this.$route.path, this.$route.query, '热门活动报名')) {
        this.$router.push({
          name: 'signUpConfirm',
          query: {
            id: this.courseDetail.id
          }
        });
      }
    },
    async ybmOnClick() {
      if (!this.ybmShow) return;
      if (await toRegister(this.$route.path, this.$route.query, '热门活动报名')) {
        this.$router.push({
          name: 'myCourseOrderDetail',
          query: {
            id: this.courseDetail.applyId
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.night-school-detail {
  background-color: #fafafa;
  box-sizing: border-box;
  padding-bottom: 100px;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
}
.course-detail-box {
  box-sizing: border-box;
  padding: 30px 0px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.footer-button {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 16px 30px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
  .hangdle-button {
    width: 80%;
    height: 68px;
    box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
    border-radius: 10px;
    font-size: 30px;
    line-height: 30px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    &.ljbm {
      background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    }
    &.ybm {
      background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
    }
    &.disabled-btn {
      background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      color: #a8a8a8;
      pointer-events: none;
    }
  }
}

.left-lint-title {
  font-size: 32px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  padding-left: 20px;
  position: relative;
  &::before {
    content: '';
    width: 8px;
    height: 28px;
    background-color: #e2e2e2;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 4px;
  }
}
</style>
